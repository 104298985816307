/* Fonts */
//@import url("https://use.typekit.net/fgr2zzs.css");

/* Sass variables */
@import "./abstracts/variables";

/* Bootstrap core imports */ 
//@import "~bootstrap/scss/bootstrap";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

/* Template CSS variables */
@import "./abstracts/css-variables";

/* Templates components */
@import "./components/navbar";
@import "./components/logos";
@import "./components/subtitles";
@import "./components/backgrounds";
@import "./components/card";

/* Template content */
@import "./content/images";
@import "./content/reboot";

/* Template utilities imports */
@import "./utilities/sizing";